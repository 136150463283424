.socialGrid {
  padding: 0px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;

  .addSocialButton {
    --padding-end: 10px;
    --padding-start: 10px;
    margin-top: 0px;
  }

  ion-row {
    ion-col {
      padding: 0px;

      ion-button {
        display: block;
        margin: auto;
        text-align: center;

        > ion-icon {
            font-size: 25px;
            margin: auto;
            display: block;
        }

        &.removeSocialBtn {
          left: 0px;
          right: -20px;
          position: absolute;
          margin-top: -10px;
          border-radius: 30px;
          --padding-bottom: 0px;
          --padding-end: 0px;
          --padding-start: 0px;
          --padding-top: 0px;
          --min-height: 0px;
          height: auto;
          border-radius: 10px;
          --border-radius: 10px;
          float: revert;
          width: 18px;
          z-index: 99999;
          height: 18px;
          line-height: 0px;

          ion-icon {
            font-size: 15px;
          }
        }
      }
    }
  }
}
