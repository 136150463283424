.profileFab {
  margin-top: -45px;
  margin-left: 20px;
  left: 0px;
  width: 80px;
  height: 80px;
  overflow: visible;
  border-radius: 10px;
  --border-radius: 10px;

  ion-fab-button {
    overflow: visible;
    width: 80px;
    height: 80px;
    --border-radius: 10px;
    border-radius: 10px;
    --background: var(--pipeline-img-background-color, var(--ion-color-medium, #fff));
    background: var(--pipeline-img-background-color, var(--ion-color-medium, #fff));

    img {
      border-radius: 10px;
      width: 80px;
      height: 80px;
    }

    ion-icon {
      position: absolute;
      right: 0px;
      top: 0px;
      background: var(--ion-color-primary) !important;
      color: var(--ion-color-primary-contrast) !important;
      z-index: 3;
      border-radius: 30px;
    }
  }
}
