.laptop {
    border-radius: 44px;
    height: 678px;
    width: 332px;
    position: relative;
    z-index: 9;

    &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        background-image: url(./../../../assets/img/devices/ipad.webp);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 100%;
        height: 100%;
        z-index: 3;
    }

    .view {
        float: left;
        width: calc(100% - 10px);
        height: calc(100% - 20px);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: #fff;
        border-radius: 44px;
        margin: 10px 5px 10px 5px;
        position: relative;
        z-index: 2;
        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        img:not(.pipeline-logo) {
            float: left;
            margin: 1.5% 3%;
            width: calc(100% - 6%);
            border-radius: 15px;
        }

    }

}