@media screen and (min-width: 1024px) {
  :root,
  ion-menu {
    --max-width: 70px !important;
    --side-min-width: 70px !important;
    --side-max-width: 70px !important;
    --side-width: 70px !important;
  }
}

ion-menu {
  border-left: none;
  border-inline-end: none;
  --padding-top: var(--ion-safe-area-top);
  padding-top: var(--ion-safe-area-top);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
  }

  .hiItem {
    margin-top: 7.5vh !important;
  }
  
  img.pipeline-logo {
    cursor: pointer;

    &.icon {
      display: none !important;
    }

  }
  
  @media screen and (min-width: 1024px) {

    img.pipeline-logo.icon {

      @media screen and (prefers-color-scheme: dark) {
        &.dark {
          display: block !important;
        }
      }

      @media screen and (prefers-color-scheme: light) {
        &.light {
          display: block !important;
        }
      }
      
    }

    img.pipeline-logo:not(.icon) {
      display: none !important;
    }

    &.subMode {
      img.pipeline-logo.icon {
        display: none !important;
      }
    }

    ion-content {
      ion-list {
        ion-button {
          margin-left: -7px !important;

          ion-label {
            display: none;
          }
        }
      }
    }

    ion-footer ion-grid {
      display: none !important;
    }

    &:not(:hover):not(:active):not(:focus) {
      ion-searchbar {
        --background: transparent !important;
        background: transparent !important;
        --box-shadow: transparent !important;
        box-shadow: transparent !important;
      }
    }

    .button-wrapper {
      --border-radius: 10px;
      border-radius: 10px;

      &.active {
        --overflow: hidden;
        overflow: hidden;
      }

    }

    .button-children,
    .chevron {
      visibility: hidden !important;
      opacity: 0 !important;
      display: none !important;
    }

    &:hover,
    &:active,
    &:focus {
      --max-width: 270px !important;
      --side-min-width: 270px !important;
      --side-max-width: 270px !important;
      --side-width: 270px !important;

      .button-wrapper.active {
        background: var(--ion-background-color);
        --background: var(--ion-background-color);

        ion-button {
          background: var(--ion-background-color);
          --background: var(--ion-background-color);
        }

      }

      .button-wrapper.active .button-children,
      .chevron {
        visibility: visible !important;
        opacity: 1 !important;
        display: block !important;
      }
      
      img.pipeline-logo.icon {
        display: none !important;
      }
  
      img.pipeline-logo:not(.icon) {

        @media screen and (prefers-color-scheme: dark) {
          &.dark {
            display: block !important;
          }
        }

        @media screen and (prefers-color-scheme: light) {
          &.light {
            display: block !important;
          }
        }

      }

      ion-content {
        ion-list {
          ion-button {
            margin-left: 0px;

            ion-label {
              display: block;
            }
          }
        }
      }

      ion-footer ion-grid {
        display: block !important;
      }

    }

  }

  ion-card {
    box-shadow: none;
  }
  
  ion-content {
    scrollbar-width: thin;
    
    ion-list {
      scrollbar-width: thin;
    }
  }

  ion-footer {
    ion-toolbar {
      ion-list {
        ion-menu-toggle {
          ion-item {
            --min-height: 50px;
            --height: 50px;
            height: 50px;
            ion-label {
              h3 {
                margin-bottom: -3px !important;
              }
            }
          }
        }
      }
    }
  }
}