ion-card#inviteFriendsScoreCard {
  ion-card-header,
  ion-card-content {
    float: left;
    width: calc(100% - 70px);
  }
  ion-card-header {
    p {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  > ion-icon {
    font-size: 40px;
    float: left;
    margin: 30px 20px;
    margin-right: 0px;
  }
}
