ion-segment {
  &:not(.md) {
    --background: transparent !important;
    background: transparent !important;
    ion-segment-button {
      --background: transparent !important;
      background: transparent !important;
      &.segment-button-checked {
        --background: transparent !important;
        background: transparent !important;
        --indicator-color: var(--ion-color-dark);
        color: var(--ion-color-dark-contrast);
      }
    }
  }

  &.md {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    ion-segment-button {
      text-transform: capitalize;
      &.segment-button-checked {
        --background: transparent !important;
        background: transparent !important;
        --indicator-color: var(--ion-color-dark);
        color: var(--ion-color-dark);
      }
    }
  }

  &.reactions {
    &.md {
      border-bottom-width: 0px;
    }

    > ion-segment-button {
      min-width: auto;

      ion-label {
        span,
        p {
          margin-top: 0px !important;
          margin-right: 5px;
        }

        small {
          margin-top: 1px;
          font-size: 13px;
        }
      }
    }

    &.segment-disabled {
      > ion-segment-button {
        opacity: 1;
        --border-width: 0px;
        border-width: 0px;

        ion-label {
          align-self: start !important;
          display: block;
        }
      }
    }

  }
  
}
