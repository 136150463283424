.codex-editor {
    .codex-editor__redactor {
        margin-right: 0px;
        //padding: 15px !important;
        //padding-bottom: 15px !important;

        .ce-block__content {
            max-width: calc(100% - 20px);
        }

    }
}