.reviewInternalAlert {
  .alert-radio-group {
    border-top-width: 0px !important;
    border-bottom-width: 0px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    button {
      float: left;
      width: 20%;
      .alert-radio-icon {
        &:not(.sc-ion-alert-ios) {
          left: 50px;
        }
        &.sc-ion-alert-ios {
          left: -15px;
        }
        border-color: transparent !important;
        filter: grayscale(90%) !important;
      }
      .alert-radio-icon::before {
        content: "⭐";
        transform: grayscale(90%) !important;
        position: relative;
        z-index: 9;
        top: -10px !important;
        font-size: 28px !important;
        opacity: 0.95;
      }
    }
  }
}
