.loader {
    --loader-background: linear-gradient(
      135deg,
      var(--ion-color-primary),
      var(--ion-color-primary)
    );
    --shadow: #{rgba(var(--ion-color-primary), 0.28)};
    --text: #6c7486;
    --page: #{rgba(#fff, 0.36)};
    --page-fold: #{rgba(#fff, 0.52)};
    --duration: 3s;
    width: 200px;
    height: 140px;
    position: relative;
    margin: auto;
    margin-top: 10vh;
    display: block;
    zoom: 0.6;
    &:before,
    &:after {
      --r: -6deg;
      content: "";
      position: absolute;
      bottom: 8px;
      width: 120px;
      top: 80%;
      box-shadow: 0 16px 12px var(--shadow);
      transform: rotate(var(--r));
    }
    &:before {
      left: 4px;
    }
    &:after {
      --r: 6deg;
      right: 4px;
    }
    div {
      width: 100%;
      height: 100%;
      border-radius: 13px;
      position: relative;
      z-index: 1;
      perspective: 600px;
      box-shadow: 0 4px 6px var(--shadow);
      background-image: var(--loader-background);
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        li {
          --r: 180deg;
          --o: 0;
          --c: var(--page);
          position: absolute;
          top: 10px;
          left: 10px;
          transform-origin: 100% 50%;
          color: var(--c);
          opacity: var(--o);
          transform: rotateY(var(--r));
          animation: var(--duration) ease infinite;
          $i: 2;
          @while $i < 6 {
            &:nth-child(#{$i}) {
              --c: var(--page-fold);
              animation-name: page-#{$i};
            }
            $i: $i + 1;
          }
          svg {
            width: 90px;
            height: 120px;
            display: block;
          }
          &:first-child {
            --r: 0deg;
            --o: 1;
          }
          &:last-child {
            --o: 1;
          }
        }
      }
    }
    span {
      display: block;
      left: 0;
      right: 0;
      top: 100%;
      margin-top: 20px;
      text-align: center;
      color: var(--text);
    }
  }