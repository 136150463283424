ion-button.verified-badge {
  z-index: 99 !important;
  top: -12px !important;
  --background: var(--ion-color-white-tint);
  border-radius: 100px;
  --border-radius: 100px;
  --padding: 0px !important;
  --padding-start: 4px;
  --padding-end: 4px;
  width: 27px;
  height: 27px;
  font-size: 28px;

  &:not(.centered) {
    position: absolute !important;
    right: -12px !important;
    top: -12px !important;
  }

  &.centered {
    width: 15px;
    height: 15px;
    font-size: 20px;
    margin-top: -5px;
    margin-left: 5px;
    --padding-start: 1px;
    --padding-end: 1px;
  }

  ion-icon {
    color: #fff;
    --color: #fff;
  }

  &.admin {
    --background: #c4ad82;
  }

  &.blog {
    --background: var(--ion-color-dark);
  }

  &.creator {
    --background: var(--ion-color-primary) !important;
  }
}