.progress {
  float: left;
  width: 100%;
  height: 5px;
  background: var(--ion-color-light);
  .progress-bar {
    background: var(--ion-color-dark);
    height: 5px;
    float: left;
    transition: 0.5s all linear;
  }

  &.round {
    border-radius: 10px;
    .progress-bar {
      border-radius: 10px;
    }
  }
}
