@import "src/theme/components/card/inviteFriendsScoreCard";

ion-card {

    ion-card-header,
    ion-card-content {
        ion-card-title {

            @media screen and (max-width: 768px) {
                --font-size: 20px;
                font-size: 20px;
            }
    
        }
    }

}