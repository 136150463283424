#switchCityBtn {
    position: absolute;
    font-size: 20px;
    float: right;
    margin-left: -10px;

    ion-icon {
        font-size: 25px;
    }
}

ion-toolbar.md {
    #switchCityBtn {
        margin-top: -7px;
    }
}