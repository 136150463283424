.btn-item {
    
    ion-icon {
        --color: var(--ion-color-dark) !important;
        color: var(--ion-color-dark) !important;
    }

    ion-thumbnail {
        width: 40px;
        height: 40px;
        margin-top: 5px;
        margin-left: -7px;
        --border-radius: 10px;
        border-radius: 10px;
    }

    &.active {
        --background: var(--ion-color-primary) !important;
        --background-hover: var(--ion-color-primary) !important;
        --background-focused: var(--ion-color-primary) !important;
        background: var(--ion-color-primary) !important;
        --border-radius: 10px;
        border-radius: 10px;
        --color: var(--ion-color-white);
        color: var(--ion-color-white);

        ion-icon,
        ion-label {
            --color: var(--ion-color-white) !important;
            color: var(--ion-color-white) !important;

            h2,
            h3,
            p {
                --color: var(--ion-color-white) !important;
                color: var(--ion-color-white) !important;
            }
        }

    }

    &.danger {
        --color: var(--ion-color-danger);
        color: var(--ion-color-danger);

        ion-icon,
        ion-label {
            --color: var(--ion-color-danger) !important;
            color: var(--ion-color-danger) !important;

            h2,
            h3,
            p {
                --color: var(--ion-color-danger) !important;
                color: var(--ion-color-danger) !important;
            }
        }
    }

    &.success {
        --color: var(--ion-color-success);
        color: var(--ion-color-success);

        ion-icon,
        ion-label {
            --color: var(--ion-color-success) !important;
            color: var(--ion-color-success) !important;

            h2,
            h3,
            p {
                --color: var(--ion-color-success) !important;
                color: var(--ion-color-success) !important;
            }
        }
    }

    &.warning {
        --color: var(--ion-color-warning);
        color: var(--ion-color-warning);

        ion-icon,
        ion-label {
            --color: var(--ion-color-warning) !important;
            color: var(--ion-color-warning) !important;

            h2,
            h3,
            p {
                --color: var(--ion-color-warning) !important;
                color: var(--ion-color-warning) !important;
            }
        }
    }

}