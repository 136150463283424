@import "src/theme/components/slides/storySlides";

swiper-container {
    --swiper-theme-color: var(--ion-color-primary);
}

swiper#introSlider {
    .swiper-pagination-bullets {
        bottom: 100px;
        .swiper-pagination-bullet {
            background: var(--ion-color-white);
        }
    }
}

pipeline-post-suggestions-card {
    margin-left: -15px;
    margin-right: -15px;
    float: left;
    width: calc(100% + 30px);

    ion-card {
        margin: 0px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }

}