*[hidden] {
  display: none !important;
}

pipeline-no-entries-card {
  margin-top: 15vh;
}

ion-popover {
  ion-list {
    ion-item {
      cursor: pointer;
    }
  }
}

@import "src/theme/components/item/btn-item";