ion-modal {

  @media screen and (min-width: 1024px) {

    &.postModal,
    &.previewPostSocialSharingImagesModal,
    &.postPreviewModal {
      .headlineToolbar {
        position: relative !important;
        max-width: 1100px;
        position: relative;
        left: 0px;
        right: 0px;
        margin: auto;
        display: block;
        min-height: 35vh !important;

        .headlineToolbarContainer {
          max-width: calc(1024px - 20px);
          left: 0px;
          right: 0px;
          margin: auto;
          display: block;

          h1 {
            padding-top: 0px;
            padding-bottom: 30px;
          }
        }

      }
    }

    &.editPostSettingsModal {
      --height: calc(100% - var(--ion-safe-area-top) + var(--ion-safe-area-bottom)) !important;
      --width: 270px;
      width: 270px;
      min-width: 270px;
      left: auto;
      right: 0px;
      top: 0px;
      height: calc(100% - var(--ion-safe-area-top) + var(--ion-safe-area-bottom)) !important;
      bottom: 44px;
      top: 0px;

      .ion-page {
        --width: 100% !important;
        width: 100% !important;
      }
    }

  }
  
  &.fullscreenModal {
    --width: 100vw;
    --height: 100vh;

    .ion-page {
      --width: 100vw;
      width: 100vw;
      --height: 100vh;
      height: 100vh;
      left: 0px;
      top: 0px;
    }
  }

  &.defaultModal {
    --width: 100vw;
    --height: 100vh;

    @media screen and (min-width: 768px) {
      .ion-page {
        --width: 100vw;
        width: 100vw;
        --height: 100vh;
        height: 100vh;
        left: 0px;
        top: 0px;
      }
    }
  }

  &.chooseActionSheetPage,
  &.defaultModal,
  &.changeAccountModal,
  &.orderByModal,
  &.pickIconModal,
  &.loginModal {
    [role="dialog"] {
      position: absolute;
      --height: 40vh;
      height: 40vh;
      --min-height: 40vh;
      min-height: 40vh;
      --max-height: 40vh;
      max-height: 40vh;
      bottom: 0px;
    }

    &.cookiesModal {
      [role="dialog"] {
        position: absolute;
        --height: 45vh;
        height: 45vh;
        --min-height: 45vh;
        min-height: 45vh;
        --max-height: 45vh;
        max-height: 45vh;
        bottom: 0px;
      }
    }

    &.defaultModal,
    &.chooseActionSheetPage,
    &.pickIconModal,
    &.loginModal,
    &.verifiedInfoModal {
      [role="dialog"] {
        --height: 90vh;
        height: 90vh;
        --min-height: 90vh;
        min-height: 90vh;
        --max-height: 90vh;
        max-height: 90vh;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        overflow: hidden;
      }

      &.pageModal,
      &.verifiedInfoModal {
        [role="dialog"] {
          background: var(--ion-color-primary) !important;
        }

        .header-background {
          backdrop-filter: none !important;
        }

        ion-header,
        ion-content {
          ion-toolbar {
            --background: var(--ion-color-primary) !important;
            --color: var(--ion-color-white) !important;
          }

          --background: var(--ion-color-primary) !important;
          --color: var(--ion-color-white) !important;
        }
      }
    }
  }

  &.defaultModal {

    @media screen and (min-width: 1024px) {
      --max-width: 100vw;
      --width: 100vw;
      --height: 100%;
      width: 100vw;
      left: auto;
      right: 0px;

      .ion-page {
        width: calc(100vw - var(--side-width));
        height: 100vh;
        left: auto;
        right: 0px;
        bottom: 0px;
      }

      &.writePostModal {
        --max-width: 100vw;
        --width: 100vw;
        width: 100vw;

        .ion-page {
          --max-width: 100vw;
          --width: 100vw;
          width: 100vw;
        }

        @media screen and (min-width: 1024px) {
          --max-width: calc(100vw - var(--side-width));
          --width: calc(100vw - var(--side-width));
          width: calc(100vw - var(--side-width));

          .ion-page {
            --width: calc(100vw - var(--side-width));
            width: calc(100vw - var(--side-width));
          }
        }
      }
    }

    @media screen and (min-width: 1366px) {
      background: transparent !important;
      --background: transparent !important;

      .ion-page {
        --border-radius: 20px;
        border-radius: 20px;
        --max-width: calc(85vw - var(--side-width));
        --width: calc(85vw - var(--side-width));
        --height: 90vh;
        width: calc(85vw - var(--side-width));
        height: 90vh;
        left: 7.5vw;
        top: 5vh;
        overflow: hidden;
      }

    }

  }

}

.loggedin {
  ion-modal.defaultModal {

    @media screen and (min-width: 1024px) {
      --max-width: calc(100vw - var(--side-width));
      --width: calc(100vw - var(--side-width));
      --height: 100%;
      width: calc(100vw - var(--side-width));
    }

  }
}