:root {
    --polotno-background-color: var(--ion-background-color) !important;
}

.polotno-wrapper {
    height: inherit;

    .polotno-workspace-container {
        flex: none !important;
        height: 100%;
    }

    .polotno-app-container {
        height: 75vh !important;

        >div:nth-child(2) {
            height: 100%;
        }
    }

    .polotno-side-panel {
        height: inherit;
        max-height: calc(100vh - 76px);

        .polotno-panel-container {
            max-height: inherit;
            height: inherit;

            > div {
                overflow-y: auto;
                height: inherit;
                max-height: inherit;
            }
        } 
    }
    
    polotnocontainer {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: calc(100vh - 76px);
    }

}

.bp5-navbar {
    background: var(--polotno-background-color, --ion-background-color) !important;
}

.bp5-button.bp5-minimal:hover,
.bp5-button:not([class*="bp5-intent-"]) {
    color: var(--ion-color-dark, #1c2127);
}